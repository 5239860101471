<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="customization-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <div class="mat-tree-node" [class.selected]="selection.isSelected(node)" (click)="toggleSelection(node)">
      <mat-icon>{{node.schema.icon}}</mat-icon>
      <spam [matTooltip]="node.schema?.label">
        <span *ngIf="!node.element['$isRemoved']">{{getLabel(node)}}</span>
        <del *ngIf="node.element['$isRemoved']">{{getLabel(node)}}</del>
      </spam>
    </div>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: canExpand">
    <div class="mat-tree-node">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" (click)="onExpandClick(node)">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <div class="mat-tree-node" [class.selected]="selection.isSelected(node)" (click)="toggleSelection(node)">
        <mat-icon>{{node.schema.icon}}</mat-icon>
        <spam [matTooltip]="node.schema?.label">
          <span *ngIf="!node.element['$isRemoved']">{{getLabel(node)}}</span>
          <del *ngIf="node.element['$isRemoved']">{{getLabel(node)}}</del>
        </spam>
      </div>
    </div>
    <div [class.customization-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

