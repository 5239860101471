const config = {
  version: '0.9.1',
  production: true,
  authenticationType: 'ActiveDirectory',
  msalModule: {
    clientID: '44c69a71-b243-48c9-880d-f3126be3714c',
    authority: 'https://arpideasrc.b2clogin.com/arpideasrc.onmicrosoft.com/B2C_1_signupsignin',
    redirectUri: '/auth',
    appScope: 'https://arpideasrc.onmicrosoft.com/9343da30-7e3e-4a42-b85e-2949ac3a0c02/access_as_user',
    showDetailedAuthLogs: false,
  },
  urlMappings: [
    {
      client: 'https://engine.onarp.cloud',
      server: 'https://engine-server.onarp.cloud',
    },
    {
      client: 'https://engine.dev.local',
      server: 'https://engine-server.dev.local',
    },
    {
      client: 'https://rc.onengine.cloud',
      server: 'https://rc-server.onengine.cloud',
    },
    {
      client: 'https://rc.dev.local',
      server: 'https://rc-server.dev.local',
    },
    {
      client: 'https://arp-engine-0-1.azurewebsites.net',
      server: 'https://arp-engine-0-1-server.azurewebsites.net',
    },
    {
      client: 'http://localhost:4200',
      server: 'https://localhost:44351',
    },
    {
      client: 'https://licensing.dev.local',
      server: 'https://licensing-server.dev.local',
    },
    {
      client: 'https://licensing.onarp.cloud',
      server: 'https://licensing-server.onarp.cloud',
    },
    {
      client: 'https://crm.onarp.cloud',
      server: 'https://crm-server.onarp.cloud',
    },
    {
      client: 'https://enginegreen.dev.local',
      server: 'https://enginegreen-server.dev.local',
    },
  ],
};
