import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomizationForms, CustomizationItems, CustomizationSchema, FEATURE_NAME, ICustomizationsState } from "./state";
import { CustomizationsFeatureState } from ".";
import { CustomizationElement, CustomizationChangeType, CustomizationChange } from "../../services/api-clients";
import { CustomizationElementWrapper } from "../models/customization-element-wrapper.model";
import { CustomizationElementFlags } from "./reducers";


export const customizationsFeatureSelector = createFeatureSelector<CustomizationsFeatureState>(FEATURE_NAME);
export const customizationsSelector = createSelector(customizationsFeatureSelector, (state: CustomizationsFeatureState) => state.customizations);
export const getRootElement = createSelector(customizationsSelector, (state: ICustomizationsState) => state.rootElement);
export const getSelectedElement = createSelector(customizationsSelector, (state: ICustomizationsState) => state.selectedElement);
export const getCustomizationPreview = createSelector(customizationsSelector, (state: ICustomizationsState) => state.preview);
export const getForms = createSelector(customizationsSelector, (state: ICustomizationsState) => state.forms);
export const getSchema = createSelector(customizationsSelector, (state: ICustomizationsState) => state.schema);
export const getDataItems = createSelector(customizationsSelector, (state: ICustomizationsState) => state.dataItems);

export const getWrappedRootElement = createSelector(getRootElement, getSchema, getForms, getDataItems, (element, schema, forms, items) => wrap(element, schema, forms, items));
export const getWrappedSelectedElement = createSelector(getSelectedElement, getSchema, getForms, getDataItems, (element, schema, forms, items) => wrap(element, schema, forms, items));

const wrap = (element: CustomizationElement, schema: CustomizationSchema, forms: CustomizationForms, items: CustomizationItems) => {
  if (element) {
    let wrapper = <CustomizationElementWrapper>{
      element: element,
      schema: schema[element.schemaId],
      childSchemas: schema[element.schemaId].childSchema.map(schemaId => schema[schemaId]).filter(x => !!x),
      form: schema[element.schemaId].isCollection ? null : forms[schema[element.schemaId].entityName],
      item: items[element.elementId] ?? element.item,
      childElements: getValidChildElements(element, items).map(x => wrap(x, schema, forms, items))
        .sort((a, b) => (a.item['Order'] ?? a.element.init['Order'] ?? 0) < (b.item['Order'] ?? b.element.init['Order'] ?? 0) ? -1 : 1)
    };

    return wrapper;
  }

  return null;
}

export const getUnpublishedCustomizationChanges = createSelector(getWrappedRootElement, (rootElement) => getChanges(rootElement));

const getChanges = (element: CustomizationElementWrapper): CustomizationChange[] => {
  if (element == null) return [];

  let change: CustomizationChange;

  if (element.element[CustomizationElementFlags.isRemoved]) {
    return [CustomizationChange.fromJS({
      entityName: element.schema.entityName,
      type: CustomizationChangeType.RemoveElement,
      recordId: element.element.item.Id,
    })];
  }

  if (element.element[CustomizationElementFlags.isAdded]) {
    change = CustomizationChange.fromJS({
      entityName: element.schema.entityName,
      type: CustomizationChangeType.AddElement,
      recordId: element.item.Id,
      item: element.item
    });
  } else {
    const patchObject = getPatchObject(element);
    if (patchObject != null) {
      change = CustomizationChange.fromJS({
        entityName: element.schema.entityName,
        type: CustomizationChangeType.UpdateElement,
        recordId: element.item.Id,
        item: patchObject
      });
    }
  }

  let changes = element.childElements?.length > 0
    ? [...element.childElements.map(child => getChanges(child)).flat()]
    : [];

  if (change != null)
    changes.push(change);

  return changes;
}

const getPatchObject = (element: CustomizationElementWrapper) => {
  let patchValue = { ...element.item };

  Object.keys(element.element.item).forEach((key) => {
    if (element.element.item[key] == patchValue[key]) delete patchValue[key];
    if (Array.isArray(patchValue[key])) delete patchValue[key];
    if (typeof patchValue[key] === 'object' && patchValue[key] != null) delete patchValue[key];
  });

  if (Object.keys(patchValue).length == 0) return null;

  patchValue["Id"] = element.item["Id"]
  return patchValue;
}


const getValidChildElements = (element: CustomizationElement, items: CustomizationItems): CustomizationElement[] => {
  const item = items[element.elementId] ?? element.item;
  if (element.schemaId == 'Column' && item['Type'] != 'Enum') {
    return element.childElements.filter(x => x.schemaId != 'Column.ColorOptionSetValues'); 
  }

  return element.childElements;
}
